import { ParseParams, ZodError } from 'zod';
import { createResponseError } from '@/lib/ResponseError';
import { createSchemaError } from '@/lib/SchemaError';

const hasMessage = (json?: unknown | null): json is { message: string } =>
    typeof (
        json as {
            message: unknown;
        }
    )?.message === 'string';

const hasError = (json?: unknown | null): json is { error: string } =>
    typeof (
        json as {
            error: unknown;
        }
    )?.error === 'string';

export const fetchJson = async <ResultType>(input: RequestInfo, init?: RequestInit) => {
    const response = await fetch(input, {
        ...init,
        headers: {
            ...init?.headers,
            'User-Agent': 'jucy-web-frontend-client/0.1 (+https://jucy.com)',
        },
        credentials: init?.credentials || 'include',
        next: {
            ...init?.next,
            revalidate: init?.next?.revalidate|| 60,
        },
    });

    if (response.headers.get('content-type') !== 'application/json') {
        const body = await response.text();
        throw createResponseError(`Invalid content type: ${response.headers.get('content-type')} for ${input}`, { cause: 'invalid-content-type', data: { body } });
    }

    const json = await response.json();

    if (!response.ok) {
        const cause = response.status === 404 ? 'not-found' : undefined;
        if (hasMessage(json)) {
            throw createResponseError(json.message, { cause, data: json });
        }
        throw createResponseError(`${response.statusText}: ${input}`, { cause });
    }
    if (hasError(json)) {
        throw createResponseError(json.error, { cause: 'error', data: json });
    }
    return json as ResultType;
};

export type Schema<TData> = {
    parse: (data: unknown, params?: Partial<ParseParams>) => TData;
};
export const schemaFetch = async <ResultType>(schema: Schema<ResultType>, input: RequestInfo, init?: RequestInit) => {
    const response = await fetchJson(input, init);
    try {
        return schema.parse(response);
    } catch (e) {
        if (e instanceof ZodError) {
            throw createSchemaError(e.issues, response);
        }
        throw e;
    }
};
